
























import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ForgotPassword extends Vue {
  private loading = true;
  private success = false;

  private email = '';

  mounted () {
    this.loading = false
  }

  async submit () {
    this.loading = true

    const result = await Services.API.Auth.RequestPasswordReset({
      Email: this.email
    })

    this.loading = false
    this.success = result.Success
  }
}
